import React, { useContext } from 'react'
import { PIMCORE_URL, ENDPOINTS } from '../../gatsby-node/constants'
import Button from 'react-bootstrap/Button'
import AppContext from '../context/app'
import rollbackVoucher from '../utilities/rollback-voucher'
import applyVoucher from '../utilities/apply-voucher'

export default function RemoveFromCart({ children, className, style, product, el }) {
    const { cartId, loadingCart, setLoadingCart, country, cart } = useContext(AppContext)
    const id = product.productId
    const session_cart_id = cartId
    const iso = country.node.country_iso_code

    async function handleOnClick() {
        setLoadingCart(true)
        if (el) el.current.classList.add('text-decoration-line-through')

        const options = {
            method: 'post',
            body: JSON.stringify({ id, session_cart_id })
        }

        const r = await fetch(`${PIMCORE_URL}${ENDPOINTS.REMOVE_FROM_CART}`, options)
        const { success, data, cart_id, message } = await r.json()
        if(!success) throw new Error(message)
        try {
            const voucher_code = cart?.voucher_code
            if (voucher_code) {
                try {
                    await rollbackVoucher(session_cart_id, iso)
                    await applyVoucher(session_cart_id, iso, voucher_code)
                } catch (err) {
                    console.error(err)
                }
            }
            if (typeof window === 'undefined') return
            const event = new Event('CART_CHANGE')
            window.dispatchEvent(event)
        } catch (e) {
            setLoadingCart(false)
            console.error(e)
            console.error(`Error removing ${product.product_name} from cart`)
        }
    }

    return (
        <Button
            variant=''
            disabled={loadingCart}
            onClick={handleOnClick}
            className={className}
            style={style}
        >
            {children}
        </Button>
    )
}

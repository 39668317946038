const BRAND_ID = 5898

const PIMCORE_URL = 'https://headless.inmusicbrands.com' // PROD
// const PIMCORE_URL = 'https://staging.headless.inmusicbrands.com' // STAGING

const ENDPOINTS = {
    /**
     * If brand_id is supplied in URL
     * Then system will pick brand domain from pimcore set in Access-Control-Allow-Origin 
     * If brand_id is not in URL.. Then default value of Access-Control-Allow-Origin header is Origin supplied in header. 
     * Which is a default behavior and running right now as well.
     */
    GET_CART_ID: (iso) => `/api/${iso.toLowerCase()}/get_cart_id?brand_id=${BRAND_ID}`,
    ADD_TO_CART: (iso) => `/api/${iso.toLowerCase()}/add_to_cart`,
    REMOVE_FROM_CART: `/api/remove_from_cart`,
    GET_CART: (iso) => `/api/${iso.toLowerCase()}/get_cart`,
    CATEGORY: `/api/get_categories?brand_id=${BRAND_ID}`,
    PRODUCT: `/api/get_products?product_type=software`,
    PRICE: `/api/get_prices?product_type=software&brand_id=${BRAND_ID}`,
    PROMOTION: `/api/get_promotions`,
    COUNTRY: `/api/pim/countries`,
    HOMEPAGE: `/api/homepage/${BRAND_ID}`,
    REDEEM_VOUCHER: (iso) => `/api/${iso.toLowerCase()}/redeem-voucher`,
    ROLLBACK_VOUCHER: (iso) => `/api/${iso.toLowerCase()}/rollback-redemption`,
    REGISTER_PRODUCT: (iso) => `/api/${iso.toLowerCase()}/register-product`,
}

const NODE_TYPES = {
    CATEGORY: 'Category',
    PRODUCT: 'Product',
    COUNTRY: 'Country',
}

const BASE_COUNTRY_ISO = 'DE'

module.exports = {
    BRAND_ID,
    PIMCORE_URL,
    ENDPOINTS,
    NODE_TYPES,
    BASE_COUNTRY_ISO,
}

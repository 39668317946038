import React, { useState } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import Modal from 'react-bootstrap/Modal'

import closeIconWhite from '../images/svgs/x-close-icon-white.svg'
import macosIconWhite from '../images/svgs/macos-icon-white.svg'
import macosIconGray from '../images/svgs/macos-icon-gray.svg'
import windowsIconWhite from '../images/svgs/windows-icon-white.svg'
import windowsIconGray from '../images/svgs/windows-icon-gray.svg'
import downloadIconBlack from '../images/svgs/download-icon-black.svg'

export default function TryItFree({ product, children = 'TRY IT FREE', className, style }) {
    const {
        product_name,
        cdnLinkMac,
        cdnLinkWindows,
        shop_categories,
    } = product

    const OPERATING_SYSTEMS = {
        MACOS: {
            name: 'MACOS',
            icon: {
                active: macosIconWhite,
                inactive: macosIconGray,
            },
            link: cdnLinkMac,
        },
        WINDOWS: {
            name: 'WINDOWS',
            icon: {
                active: windowsIconWhite,
                inactive: windowsIconGray,
            },
            link: cdnLinkWindows,
        },
        MPC: {
            name: 'MPC/Force',
            icon: {
                active: '',
                inactive: '',
            },
            link: '',
            description: 'You can activate a trial of this plugin on your standalone MPC OS device by going to the Activations tab in Preferences, and pressing the “GET TRIAL” button.'
        },
    }

    function getOS() {
        if (typeof window === 'undefined') return

        const userAgent = window.navigator.userAgent
        const platform = window.navigator?.userAgentData?.platform || window.navigator.platform

        const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
        const iosPlatforms = ['iPhone', 'iPad', 'iPod']

        if (macosPlatforms.indexOf(platform) !== -1) {
            return 'Mac OS'
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            return 'iOS'
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            return 'Windows'
        } else if (/Android/.test(userAgent)) {
            return 'Android'
        } else if (/Linux/.test(platform)) {
            return 'Linux'
        }
    }

    const [show, setShow] = useState(false)
    const [os, setOs] = useState(getOS() === 'Windows' ? OPERATING_SYSTEMS.WINDOWS : OPERATING_SYSTEMS.MACOS)

    const subtractiveBorder = 'border border-top-0 border-end-0 border-start-0 border-primary border-4'

    if (!cdnLinkMac || !cdnLinkWindows) return
    return (
        <>
            {/* TRY IT FREE OPEN MODAL */}
            <Button
                variant=''
                onClick={() => setShow(true)}
                className={`${className} ${shop_categories[0]?.slug === 'expansions' ? 'd-none' : ''}`}
                style={style}
            >
                {children}
            </Button>

            {/* MODAL POPUP */}
            <Modal
                fullscreen
                show={show}
                onHide={() => setShow(false)}
            >
                {/* X TO CLOSE BUTTON */}
                <Modal.Header
                    className='border-0 justify-content-end'
                    style={{ background: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.9))' }}
                >
                    <Button variant='' onClick={() => setShow(false)}>
                        <img src={closeIconWhite} alt='' />
                    </Button>
                </Modal.Header>

                {/* MODAL BODY CONTENT */}
                <Modal.Body style={{ background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0.75))' }}>
                    <Container>
                        <section className='text-center text-white'>
                            {/* TITLE */}
                            <h2 className='text-uppercase mb-5' style={{ fontSize: 40 }}>
                                DOWNLOAD THE DEMO OF<br />
                                <span className='text-primary'>{product_name}</span>
                            </h2>

                            {/* TAB BUTTONS */}
                            <Stack direction='horizontal' className='justify-content-center mb-5' gap={5}>
                                {Object.keys(OPERATING_SYSTEMS).map(key => {
                                    const obj = OPERATING_SYSTEMS[key]
                                    const { name, icon } = obj

                                    return (
                                        <Button
                                            variant=''
                                            className={`d-flex align-items-center gap-2 ${os.name === name ? `text-white ${subtractiveBorder}` : 'text-secondary'}`}
                                            onClick={() => setOs(obj)}
                                        >
                                            {(icon.active && icon.inactive) && <img src={os.name === name ? icon.active : icon.inactive} alt='' />}
                                            {name}
                                        </Button>
                                    )
                                })}
                            </Stack>

                            {/* TAB CONTENT IS EITHER DOWNLOAD BUTTON OR DESCRIPTION TEXT */}
                            <Row className='justify-content-center'>
                                <Col md={6} style={{ fontSize: 14 }}>
                                    {
                                        os.link ?
                                            <p className='fw-bold mb-5'>
                                                Click below to download and install the 10 day trial of the {product_name} desktop VST, AU, or AAX version of this plugin.<br/>
                                                <a
                                                    href={os.link}
                                                    className='ff-primary border-primary bg-primary text-black fw-medium px-5 py-3 mb-4 inline-flex-center gap-2 mt-5'
                                                    style={{ fontSize: 18 }}
                                                >
                                                    <img src={downloadIconBlack} alt='' />
                                                    DOWNLOAD FOR {os.name}
                                                </a>
                                            </p>
                                            :
                                            <p>{os.description}</p>
                                    }
                                </Col>
                            </Row>
                        </section>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}